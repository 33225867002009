<template>
  <div class="modal-card">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">{{ goalname }} here</p>
    </header>
    <section class="modal-card-body" style="padding: 0 !important; height: 100%; background-color: red;">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <!-- mindmap: start -->
      <mindmap v-model="mmdata" :zoomable="true" :keyboard="true" style="height: 100%"></mindmap>
      <!-- <div>
        <div ref="mindmap" id="mindmap" :style="mmStyle">
          <svg ref="svg" tabindex="0">
            <g ref="content" id="content" ></g>
          </svg>
          <div ref="dummy" id="dummy"></div>
          <div ref="menu" id="menu" tabindex="0" v-show="showMenu" :style="{ top: menuY+'px', left: menuX+'px' }" @blur="showMenu = false" >
            <div class="menu-item" v-for="(item, index) in items" :key="index" @click="clickMenu(item)">
              <div>{{ item.title }}</div>
            </div>
          </div>
          <div ref="button" class="button">
            <button v-show="gps" class="icon" ref="gps" type="button" @click="makeCenter()">
              <i class="gps"></i>
            </button>
            <button v-show="fitView" class="icon" ref="fitView" type="button" @click="fitContent()">
              <i class="fitView"></i>
            </button>
          </div>
        </div>
        <canvas
          ref="canvas"
          :width="width"
          :height="height"
        ></canvas>
      </div> -->
      <!-- mindmap: end -->
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <div v-if="!this.isgoal">
        <b-button @click="saveData(true)">
          Save
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import ModalTask from "@/modals/ModalTask.vue";
import ObjectID from "bson-objectid";

import mindmap from '@hellowuxin/mindmap';
import JSONData from '../JSONData';

export default {
  name: "ModalTree",
  components: { mindmap },
  mixins: [Utils, Schemas],
  props: {
    value: { type: Array, required: true },
    width: Number,
    height: Number,
    data: { type: Object, default: {} },
    xSpacing: { type: Number, default: 80 },
    ySpacing: { type: Number, default: 20 },
    draggable: { type: Boolean, default: true },
    gps: { type: Boolean, default: true },
    fitView: { type: Boolean, default: true },
    download: { type: Boolean, default: true },
    usegoal: { type: Boolean, default: false },
    usegoalid: { type: String, default: '' },
    usegoalname: { type: String, default: '' },
    usegoalcolor: { type: String, default: '' },
    usebus: { type: String, default: 'maps' },
  },
  model: { // Two-way binding
    prop: 'value',
    event: 'change'
  },
  computed: {
    mmStyle() {
      return {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
      }
    }
  },

data: () => ({
  loading: false,
  aheight: '100%',
    mmdata: [{
      "name":"如何学习D3",
      "children": [
        {
          "name":"预备知识",
          "children": [
            { "name":"HTML & CSS" },
            { "name":"JavaScript" },
          ]
        },
        {
          "name":"安装",
          "_children": [
            { "name": "折叠节点" }
          ]
        },
        {
          "name":"进阶",
          "left": true
        },
      ]
    }]
  }),


    
  // data: () => ({
  //   loading: false,
  //   dTop: null,
  //   mmdata: Object,// Mind map data
  //   mmdataclean: [],// Mind map data
  //   root: '',
  //   showMenu: false,
  //   menuX: 0,
  //   menuY: 0,
  //   items: [
  //     { title: 'Edit', command: 1 },
  //     { title: 'Delete', command: 0 },
  //   ],
  //   mindmap_svg: Object,
  //   mindmap_g: Object,
  //   dummy: Object,
  //   mindmapSvgZoom: Function,
  //   isgoal: false,
  //   goalid: '',
  //   goalname: '',
  //   goalcolor: '',
  //   goal: {},
  //   bus: 'maps',
  //   clickedinfo: {},
  //   isDemo: false,
  //   sub: localStorage.getItem("sub"),
  // }),
  // watch: {
  //   mmdata: {
  //     handler(newVal) {
  //       this.updateMindmap(newVal.data[0]);
  //       if (this.draggable) { this.makeDrag() }
  //       this.$emit('change', this.mmdata.getPuredata())
  //     },
  //     deep: true,// watch for nested data
  //   },
  //   draggable: function(val) {
  //     if (!val) { this.cancelDrag() } 
  //     else { this.makeDrag(); }
  //   },
  //   xSpacing: function() {
  //     this.updateMindmap();
  //   },
  //   ySpacing: function() {
  //     this.updateMindmap();
  //   }
  // },

  created() {
    console.log('mindmap', mindmap);
    // const incolcount = this.value[0].children.length;
    // this.mmdata = new JSONData(this.value, incolcount);
    this.mmdata = this.value;
    this.isgoal = this.usegoal;
    this.goalid = this.usegoalid;
    if (this.goalid !== '') {
      this.items.push({ title: 'View Task', command: 2 });
      this.items.push({ title: 'Collapse', command: 3 });
      this.items.push({ title: 'Expand', command: 4 });
      // this.items.push({ title: 'Copy Task', command: 5 });
    }
    this.goalname = this.usegoalname;
    this.goalcolor = this.usegoalcolor;
    this.bus = this.usebus;
    if (this.isgoal) {
      this.goal = this.data;
      this.isDemo =  this.goal.demo;
      if (this.goal.sub === this.sub) {
        this.isDemo = false;
      }
    }
    this.bus = this.usebus;
  },

  watch: {
    mmdata: {
      handler(newVal) {
        this.saveData();
        // this.updateMindmap(newVal.data[0]);
        // if (this.draggable) { this.makeDrag() }
        // this.$emit('change', this.mmdata.getPuredata())
      },
      deep: true,// watch for nested data
    },
    draggable: function(val) {
      if (!val) { this.cancelDrag() } 
      else { this.makeDrag(); }
    },
    xSpacing: function() {
      this.updateMindmap();
    },
    ySpacing: function() {
      this.updateMindmap();
    }
  },

  methods: {


    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
      }
      this.ModalWidth = newsize;
    },
  
    // task: start
    async taskOpen(task) {
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }
      this.TaskActive = task;
      const that = this;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.TaskActive, // this is the row data that gets sent to the modal
          callBus: "FromTree" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {}
        }
      });
    },
    reidchildren(data, parentid) {

    },
    reid(data) {
      const id = new ObjectID().str;
    },
    collapse(d) {
      if (d.children) {
          d._children = d.children;
          d._children.forEach(this.collapse);
          d.children = null;
      }
    },

    expand(d) {
      if (d._children) {
          d.children = d._children;
          d.children.forEach(this.expand);
          d._children = null;
      }
    },
    // task: end
    saveData() {
      if(this.isgoal) {
        // this.DataSend("post", "/tasks/sort", d.data.children, '', false);
      } else {
        this.$emit(this.bus, this.mmdata);
      }
      // const cleandata = this.mmdata.getPuredataCol();
      // this.$emit(this.bus, cleandata);
    },
    saveDataClick(data, id) {
      if(this.isgoal) {
      } else {
      }
      // const cleandata = this.mmdata.getPuredataCol();
      // this.$emit(this.bus, cleandata);
    },
  },
  async mounted() {
  },
};
</script>

<style lang="scss">
$seleColor: rgba($color: blue, $alpha: 0.15);

div#mindmap {
  font-size: 14px;
  position: relative;
  display: flex;

  div.rightClickTrigger {
    position: absolute; 
    width: 100%; 
    height: 100%
  }

  div#dummy {
    div {
      white-space:pre-wrap;
      width: max-content;
    }
  }

  svg {
    flex: auto;
    outline: none;
    background-color: rgb(238, 238, 243);

    foreignObject {
      cursor: default;
      border-radius: 3px;
      border-width: 3px;
      border-color: transparent;
      border-style: solid;
      display: flex;

      div {
        text-align: left;
        border: 1px solid transparent;
        width: max-content;
        white-space:pre-wrap;
        color: rgb(109, 109, 109);

        &:focus {
          border-color: rgb(154, 154, 154);
          outline: none;
        }
      }
    }

    g.depth_0.node > foreignObject { 
      background-color: white;
      border-radius: 5px;

      div {
        color: rgb(75, 75, 75);
      }
    } 

    g.gButton {
      opacity: 0;
      > {
        path {
          fill: blue;
        }
        rect {
          fill: white;
          stroke: grey;
          stroke-width: 0.5;
        }
      }
    }

    path {
      fill: none;
      stroke-linecap: round;
      stroke-width: 4;
    }

    #selectedNode:not(.depth_0) > foreignObject {
      background-color: $seleColor;
    }

    #selectedNode.depth_0 > foreignObject {
      outline: 3px solid;
      outline-color: $seleColor;
    }

    #newParentNode > foreignObject {
      border-color: $seleColor;
    }

    #editing > foreignObject {
      background-color: white;
    }
    #editing > foreignObject > div {
      background-color: white;
      padding: 0px 5px;
    }
  }

  #menu {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #FAFAFA;
    padding: 4px 0;

    &:focus {
      outline: none;
    }

    .menu-item {
      position: relative;
      padding: 4px 8px;
      cursor: pointer;

      &::before {
        background-color: black;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }

      &:hover::before {
        opacity: 0.09;
      }
    }
  }
  
  div.button {
    position: absolute;
    bottom: 0;
    right: 0;
    button.icon {
      position: relative;
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      color: #3f51b5;

      &::before {
        background-color: currentColor;
        border-radius: inherit;
        bottom: 0;
        color: inherit;
        content: "";
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      }

      &:focus {
        outline: none;
      }

      &:hover::before {
        opacity: 0.1;
      }

      i {
        width: 24px;
        height: 24px;
        filter: invert(25%) sepia(40%) saturate(5050%) hue-rotate(227deg) brightness(78%) contrast(74%);

        &.gps {
          background-image: url(../../public/icons/crosshairs-gps.png);
        }

        &.fitView {
          background-image: url(../../public/icons/fit-to-page-outline.png);
        }

        &.download {
          background-image: url(../../public/icons/download.png);
        }
      }
    }
  }
}
</style>