import * as d3 from 'd3';
let colorNumber = 0;
const colorScale = d3.scaleOrdinal(d3.schemePaired);// Color list
function isEqualJSON(a, b) { // Determine a, b is exactly the same
  // Limitations:
  // If the position of the property in the object changes, the converted string is not equal
  // But in practice we just need to see if their content is consistent, and it has nothing to do with the order, so this approach has limitations.
  const aStr = JSON.stringify(a);
  const bStr = JSON.stringify(b);
  if (aStr === bStr) {
    return true;
  }
  return false;
}
function breadthTraverse(d, c) { // Bread Traversal
  if (d.children) {
    for (let index = 0; index < d.children.length; index += 1) {
      const dChild = d.children[index];
      if (!c) {
        if (!dChild.color) {
          dChild.color = colorScale(colorNumber);
        }
        colorNumber += 1;
      } else {
        dChild.color = c;
      }
    }
    for (let index = 0; index < d.children.length; index += 1) {
      const dChild = d.children[index];
      breadthTraverse(dChild, dChild.color);
    }
  }
}
function inheritColor(d, c) { // Give a new color and update the color of the child node
  if (d.children) {
    for (let index = 0; index < d.children.length; index += 1) {
      const dChild = d.children[index];
      dChild.color = c;
      inheritColor(dChild, c);
    }
  }
}
class JSONData {
  constructor(d, colno) { // d is an array
    
    colorNumber = colno;

    this.data = JSON.parse(JSON.stringify(d));// Deep copy objects
    breadthTraverse(this.data[0]);
    this._addId();
  }

  _addId(id = '', d = this.data) { // Add a unique identity
    for (let index = 0; index < d.length; index += 1) {
      const dChild = d[index];
      dChild.id = `${id}${index}`;
      if (dChild.children) {
        this._addId(`${id}${index}`, dChild.children);
      }
    }
  }

  getPuredata(d = JSON.parse(JSON.stringify(this.data))) {
    for (let index = 0; index < d.length; index += 1) {
      const dd = d[index];
      const keys = Object.keys(dd);
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        if (k !== 'name' && k !== 'children') {
          delete dd[k];
        }
      }
      if (dd.children) {
        this.getPuredata(dd.children);
      }
    }
    return d;
  }

  getPuredataCol(d = JSON.parse(JSON.stringify(this.data))) {
    for (let index = 0; index < d.length; index += 1) {
      const dd = d[index];
      const keys = Object.keys(dd);
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        if (k !== 'name' && k !== 'children' && k !== 'color') {
          delete dd[k];
        }
      }
      if (dd.children) {
        this.getPuredataCol(dd.children);
      }
    }
    return d;
  }

  del(s) { // Delete s
    const parent = this.getParent(s);
    if (parent) {
      const children = parent.children;
      let position = 0;

      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        if (isEqualJSON(child, s)) {
          position = index;
        }
      }
      children.splice(position, 1);
      this._addId(parent.id, parent.children);
    }
  }

  getItself(d, data = this.data) {
    let dSelf = data;
    const id = d.id.split('').map(s => parseInt(s, 10));
    if (id.length > 0) {
      for (let index = 0; index < id.length - 1; index++) {
        const number = id[index];
        dSelf = dSelf[number].children;
      }
      dSelf = dSelf[id[id.length - 1]];
      return dSelf; 
    }
    return false;
  }

  add(dParent, d) { // dParent adds child node d
    const parent = this.getItself(dParent);
    if (parent.id === '0') { // Root node
      if (!d.color) {
        d.color = colorScale(colorNumber);
        colorNumber += 1;
      }
    } else {
      d.color = parent.color; // Inheriting the color of the parent node
    }
    inheritColor(d, d.color);
    d.id = `${parent.id}${parent.children.length}`;
    parent.children.push(d);
    this._addId(`${d.id}`, d.children);
  }

  getParent(d, data = this.data) {
    let dParent = data;
    const id = d.id.split('').map(s => parseInt(s, 10));
    id.pop();
    if (id.length > 0) {
      for (let index = 0; index < id.length - 1; index++) {
        const number = id[index];
        dParent = dParent[number].children;
      }
      dParent = dParent[id[id.length - 1]];
      return dParent; 
    }
    return false;
  }

  insert(dPosition, d, i = 0) { // Insert d in front of dPosition (i-0) or back (i-1)
    const parent = this.getParent(dPosition);
    if (parent) {
      const children = parent.children;
      let position = 0;

      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        if (isEqualJSON(child, dPosition)) {
          position = index;
        }
      }
      if ((position+i) < children.length) { // Update id
        if (parent.id === '0') { //Root node
          if (!d.color) {
            d.color = colorScale(colorNumber);
            colorNumber += 1;
          }
        } else {
          d.color = parent.color; // Inheriting the color of the parent node
        }
        children.splice(position + i, 0, d);
        this._addId(parent.id, children)
      } else {
        this.add(parent, d);
      }
    }
  }
}

export default JSONData;
